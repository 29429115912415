import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import Style from '../styles/pages/sitemap.module.scss'

class LegalNotice extends React.Component {

  render() {
    const { data } = this.props;
    const staticPrismicTexts = data.staticText.edges[0].node.data.texts;
    const content = data.allPrismicPageLegalnotice.edges[0].node.data;

    return (
      <>
        <Layout 
          context={this.props.pageContext} 
          pageTitle={content.seo__meta_title_.text}
          _t={staticPrismicTexts}
        >
        <SEO 
          pageTitle={content.seo__meta_title_.text} 
          pageDescription={content.seo__meta_description_.text} 
          context={this.props.pageContext}
        />
        <div className="page">
          <section className="regular">
            <div className={`page-head`}>
              <h2>{content.title.text}</h2>
            </div>
            <div
              style={{paddingBottom: 200}} 
              className={`${Style.container} container`} 
              dangerouslySetInnerHTML={{__html: content.content.html}}
            >
            </div>
          </section>
        </div>
        </Layout>
      </>
    );
  }
}

export default LegalNotice;

export const query = graphql`
query legalNoticePage($locale: String!) {
  allPrismicPageLegalnotice(filter: { lang: { eq: $locale} }) {
    edges {
      node {
        lang
        data {
          seo__meta_title_ {
            text
          }
          seo__meta_description_ {
            text
          }
          title {
            text
          }
          content {
            html
          }
        }
      }
    }
  }
  staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
    edges { ...StaticTexts }
  }
}`